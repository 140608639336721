const InfusionsName = (name) => {
    const allowedNames = [
        "Koda's Warmth Enrichment",
        "Chak Egg Sac",
        "Chak Infusion",
        "Vial of Liquid Aurillium",
        "Liquid Aurillium Infusion",
        "Preserved Queen Bee",
        "Queen Bee Infusion",
        "Ghostly Infusion",
        "Ember Infusion",
        "Phospholuminescent Infusion",
        "Polysaturating Reverberating Infusion (Gray)",
        "Polysaturating Reverberating Infusion (Purple)",
        "Polysaturating Reverberating Infusion (Red)",
        "Polyluminescent Undulating Refractor (Black)",
        "Polyluminescent Undulating Infusion (Black)",
        "Polyluminescent Undulating Refractor (Green)",
        "Polyluminescent Undulating Infusion (Green)",
        "Polyluminescent Undulating Refractor (Orange)",
        "Polyluminescent Undulating Infusion (Orange)",
        "Polyluminescent Undulating Refractor (Teal)",
        "Polyluminescent Undulating Infusion (Teal)",
        "Celestial Infusion (Blue)",
        "Celestial Infusion (Red)",
        "Winter's Heart Infusion",
        "Snow Diamond Infusion",
        "Toy-Shell Infusion",
        "Moto's Unstable Bauble Infusion: Blue",
        "Moto's Unstable Bauble Infusion: Red",
        "Festive Confetti Infusion",
        "Crystal Infusion",
        "Mystic Infusion",
        "Peerless Infusion",
        "Heart of the Khan-Ur",
        "Frost Legion Infusion",
        "Otter's Blessing Enrichment",
        "Celebratory Birthday Enrichment",
        "Abyssal Infusion",
        "Deldrimor Stoneskin Infusion",
        "Jormag Left Eye Infusion",
        "Jormag Right Eye Infusion",
        "Primordus Left Eye Infusion",
        "SmackPrimordus Right Eye Infusionin",
        "Imperial Everbloom",
        "Echo of the Dragonvoid",
        "Clockwork Infusion",
        "Mistwalker Infusion",
        "Mote of Darkness",
        "Silent Symphony",
        "Arcane Flow Infusion",
        "Wurm's"
    ];
    return allowedNames.some(allowedName => name.includes(allowedName));;
};

export default InfusionsName;